import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import ImportsList from 'src/components/users/ImportsList';
import { isAutenticated } from '../services/login.service';
import { getAllSubsidiaries, getStatusImports } from '../services/middleware.service';

const ImportHistoryPage = () => {
  const navigate = useNavigate();
  const [statusData, setstatusData] = useState();
  const [subsidiaries, setGTFS] = useState();
  const [isRequesting, setisRequeting] = useState(false);

  const loadStatusImports = () => {
    setisRequeting(true);
    getAllSubsidiaries().then((subsidiariesData) => {
      setGTFS(subsidiariesData);
    });
    getStatusImports().then((res) => {
      const { data } = res;
      setstatusData(data);
      setisRequeting(false);
    });
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadStatusImports();
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>RTM - Historique d&apos;imports</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 7
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            {statusData && <ImportsList subsidiaries={subsidiaries.data} data={statusData} isRequesting={isRequesting} />}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default ImportHistoryPage;
